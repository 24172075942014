<template lang="pug">
  section.design-your-own
    .design-your-own__content.container.is-max-fullhd
      .design-your-own__wrapper
        h2.design-your-own__title {{$t('homePage.designYourOwn.title')}}
        v-link.design-your-own__link.is-underline(:to="{name: 'custom-design-gemstone-rings-jewelry'}") {{$t('homePage.designYourOwn.link')}}
        .design-your-own__jewelrys-block
          v-link.design-your-own__jewelry-block.jewelry-block(v-for="itemImg in itemImages" :key="itemImg.id", :to="itemImg.url")
            .jewelry-block__image
              v-image(:src="itemImg.src", :alt="$t(`homePage.designYourOwn.${itemImg.name}`)")
            .jewelry-block__title {{ $t(`homePage.designYourOwn.${itemImg.name}`) }}
</template>

<script>
export default {
  name: 'DesignYourOwnComponent',

  computed: {
    itemImages() {
      return [
        {
          id: 'c261f4c4-868a-45bd-9713-4d32bb5ace2e',
          src: 'details/ring@2x.jpg',
          name: 'rings',
          url: '/gemstone-ring-settings/?metals=5'
        },
        {
          id: '14358b70-ac6d-4163-976b-c0aa6e685c16',
          src: 'details/earring@2x.jpg',
          name: 'earrings',
          url: '/gemstone-earring-settings/?metals=5'
        },
        {
          id: 'ab2e8a0c-5c07-4f85-82e4-619f7a39185d',
          src: 'details/necklaces@2x.jpg',
          name: 'necklacesPendants',
          url: '/gemstone-necklace-pendant-settings/?metals=5'
        },
        {
          id: 'ca61d55d-8bef-411e-a390-f33f5087226d',
          src: 'details/bracelets@2x.jpg',
          name: 'bracelets',
          url: '/gemstone-bracelet-settings/?metals=5'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.design-your-own
  padding-top: 122px
  padding-bottom: 170px
  position: relative
  +mobile
    padding-top: 80px
    padding-bottom: 100px
  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: calc(50% + 40px)
    background: $secondary-bg-color
    z-index: -1
    +touch
      height: 410px
    +mobile
      height: 330px
  &__wrapper
    text-align: center

  &__title
    font-family: $lora
    font-style: italic
    font-size: 36px
    line-height: 42px
    margin-bottom: 22px
    +touch
      font-size: 32px
      line-height: 42px
      margin-bottom: 24px
    +mobile
      margin-bottom: 32px
  &__link
    font-family: $lato
    font-size: 16px
    line-height: 24px
    display: inline-block
    margin-bottom: 60px
    +touch
      margin-bottom: 40px
    +mobile
      margin-bottom: 32px
  &__jewelrys-block
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 34px
    +touch
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 20px
      grid-row-gap: 32px
.jewelry-block
  text-align: center
  &__image
    margin-bottom: 28px
    ::v-deep
      img
        max-width: 311px
        width: 100%
        aspect-ratio: 1 / 1
        //+mobile
        +touch
          max-width: 100%
          aspect-ratio: 1 / 1

  &__title
    font-family: $lato
    font-weight: bold
    font-size: 18px
    line-height: 22px
    +mobile
      font-size: 16px
      line-height: 20px
</style>
